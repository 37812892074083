import { bankApi, cw20Api } from '@/apis';
import { API } from '@/constants';

export default {
  initCW20({ commit }) {
    commit('reset');
  },
  async fetchReceived({ commit, rootGetters }, params) {
    const token = rootGetters['token'];
    commit('setLoading', true);
    try {
      const response = await cw20Api.requestReceived(token, params);
      commit('setResponse', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchSent({ commit, rootGetters }, params) {
    const token = rootGetters['token'];
    commit('setLoading', true);
    try {
      const response = await cw20Api.requestSent(token, params);
      commit('setResponse', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  async initCW20Send({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchUserBalances')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchUserBalances({ commit, rootGetters }) {
    const token = rootGetters['token'];
    const params = { type: API.PARAM.TYPE.CW20 };
    try {
      const response = await bankApi.requestBalance(token, params);
      commit('setBalances', response.data.balance);
    } catch (error) {
      commit('setError', error);
    }
  },
  async sendCW20({ commit, rootGetters }, data) {
    const token = rootGetters['token'];
    commit('setSending', true);
    try {
      const response = await cw20Api.requestSend(token, data);
      commit('setResponse', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setSending', false);
    }
  },
};
